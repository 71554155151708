import './GoogleLogin.css';

import { Box, Image } from '@mantine/core';

import GoogleIcon from './GoogleIcon.svg';

export default function GoogleLogin() {
  return (
    <Box>
      <button className="gsi-material-button">
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <Image src={GoogleIcon} alt="Google" />
          </div>
          <span className="gsi-material-button-contents">Continue with Google</span>
        </div>
      </button>
    </Box>
  );
}
